<script lang="ts" setup>
import type { EditorialMarqueeStripProps } from '@design-system/components/Editorial/EditorialMarqueeStrip.props'
import type { StripLayout } from '@integration-layer/ampliance/schemas/strip-layout-schema.localized'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: StripLayout
}>()

const data = await useComponentResponse<StripLayout>(props)
// TODO: handle fallbacks
const transformed = computed<EditorialMarqueeStripProps>(() => {
  return data.value as unknown as EditorialMarqueeStripProps
})
</script>

<template>
  <EditorialMarqueeStrip v-bind="transformed"></EditorialMarqueeStrip>
</template>
